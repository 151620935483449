import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";

const MentionsLegales = ({ data }) => {
  return (
    <Layout>
      <SEO title="Mentions légales" />
      <Banner
        title="Mentions légales"
        baseline="(et conditions générales d'utilisation)"
        imageBanner={data.imageBanner.childImageSharp.fluid}
      />
      <section id="section-full-width">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2>Présentation du site</h2>
              <p>
                Conformément aux dispositions des articles 6-III et 19 de la Loi
                n° 2004-575 du 21 juin 2004 pour la Confiance dans l'économie
                numérique, dite L.C.E.N., nous portons à la connaissance des
                utilisateurs et visiteurs du site : www.azerty-studio.fr les
                informations suivantes :
              </p>
              <p className="text-bold">Informations légales :</p>
              <ul>
                <li>
                  <span className="text-bold">Statut du propriétaire :</span>{" "}
                  societe
                </li>
                <li>
                  <span className="text-bold">Préfixe :</span> Auto-entrepreneur
                </li>
                <li>
                  <span className="text-bold">Nom de la Société :</span>{" "}
                  Charlotte Cady - Azerty Studio
                </li>
                <li>
                  <span className="text-bold">Adresse :</span> 36 rue du Haut
                  Moreau, 44000 Nantes
                </li>
                <li>
                  <span className="text-bold">Tél :</span> 06 88 34 88 62
                </li>
                <li>
                  <span className="text-bold">SIRET :</span> 79045564600030
                </li>
                <li>
                  <span className="text-bold">R.C.S. :</span> RSC Nantes
                </li>
                <li>
                  <span className="text-bold">
                    Adresse de courrier électronique :
                  </span>{" "}
                  charlotte@azerty-studio.fr
                </li>
              </ul>
              <ul>
                <li>
                  <span className="text-bold">Le Créateur du site est :</span>{" "}
                  Charlotte Cady
                </li>
                <li>
                  <span className="text-bold">
                    La Responsable de la publication est :
                  </span>{" "}
                  Charlotte Cady
                </li>
                <li>
                  <span className="text-bold">
                    Contactez la responsable de la publication :
                  </span>
                  charlotte@azerty-studio.fr
                </li>
                <li>
                  La responsable de la publication est une personne physique
                </li>
                <li>
                  <span className="text-bold">La Webmaster est :</span>{" "}
                  Charlotte Cady
                </li>
                <li>
                  <span className="text-bold">Contactez la Webmaster :</span>{" "}
                  charlotte@azerty-studio.fr
                </li>
                <li>
                  <span className="text-bold">L’hebergeur du site est :</span>{" "}
                  Netlify, 2325 3rd Street, Suite 215, San Francisco, États-Unis
                </li>
              </ul>

              <h2>Définitions</h2>
              <p>
                La présente clause a pour objet de définir les différents termes
                essentiels du contrat. Utilisateur : ce terme désigne toute
                personne qui utilise le site ou l'un des services proposés par
                le site. Contenu utilisateur : ce sont les données transmises
                par l'Utilisateur au sein du site.
              </p>

              <h2>Description des services fournis</h2>
              <p>
                Le site www.azerty-studio.fr a pour objet de fournir une
                information concernant l’ensemble des activités de la société.
                <br />
                Le proprietaire du site s’efforce de fournir sur le site
                www.azerty-studio.fr des informations aussi précises que
                possible. Toutefois, il ne pourra être tenue responsable des
                omissions, des inexactitudes et des carences dans la mise à
                jour, qu’elles soient de son fait ou du fait des tiers
                partenaires qui lui fournissent ces informations.
                <br /> Tous les informations proposées sur le site
                www.azerty-studio.fr sont données à titre indicatif, sont non
                exhaustives, et sont susceptibles d’évoluer. Elles sont données
                sous réserve de modifications ayant été apportées depuis leur
                mise en ligne.
              </p>

              <h2>Propriété intellectuelle et contrefaçons</h2>
              <p>
                Le proprietaire du site est propriétaire des droits de propriété
                intellectuelle ou détient les droits d’usage sur tous les
                éléments accessibles sur le site, notamment les textes, images,
                graphismes, logo, icônes, sons, logiciels…
                <br /> Toute reproduction, représentation, modification,
                publication, adaptation totale ou partielle des éléments du
                site, quel que soit le moyen ou le procédé utilisé, est
                interdite, sauf autorisation écrite préalable à l'email :
                charlotte@azerty-studio.fr.
                <br /> Toute exploitation non autorisée du site ou de l’un
                quelconque de ces éléments qu’il contient sera considérée comme
                constitutive d’une contrefaçon et poursuivie conformément aux
                dispositions des articles L.335-2 et suivants du Code de
                Propriété Intellectuelle.
              </p>

              <h2>Liens hypertextes et cookies</h2>
              <p>
                Le site www.azerty-studio.fr contient un certain nombre de liens
                hypertextes vers d’autres sites (partenaires, informations …)
                mis en place avec l’autorisation du proprietaire du site .
                Cependant, le proprietaire du site n’a pas la possibilité de
                vérifier le contenu des sites ainsi visités et décline donc
                toute responsabilité de ce fait quand aux risques éventuels de
                contenus illicites.
              </p>
              <p>
                {" "}
                L’utilisateur est informé que lors de ses visites sur le site
                www.azerty-studio.fr, un ou des cookies sont susceptibles de
                s’installer automatiquement sur son ordinateur. Un cookie est un
                fichier de petite taille, qui ne permet pas l’identification de
                l’utilisateur, mais qui enregistre des informations relatives à
                la navigation d’un ordinateur sur un site. Les données ainsi
                obtenues visent à faciliter la navigation ultérieure sur le
                site, et ont également vocation à permettre diverses mesures de
                fréquentation.
              </p>
              <p>
                Plus précisément, ce site utilise des cookies pour{" "}
                <strong>Google Analytics</strong>. Ces cookies aident Azerty
                Studio à identifier le contenu qui intéresse le plus
                l'utilisateur ainsi qu'à repérer certains dysfonctionnements.
                Les données de navigations sur ce site sont envoyées à Google
                Inc.
                <br />
                Les données collectées de Google Analytics ne sont pas recoupées
                avec d’autres traitements, le cookie déposé de Google Analytics
                sert uniquement à la production de statistiques anonymes et ne
                permet pas de suivre la navigation de l'utilisateur sur d’autres
                sites. L’adresse IP permettant de géolocaliser l'utilisateur
                n'est pas plus précise que l’échelle de la ville. Les cookies de
                Google Analytics permettant la traçabilité des utilisateurs ne
                sont pas conservés au-delà de 13 mois à compter de la première
                visite.
              </p>
              <p>
                {" "}
                Le paramétrage du logiciel de navigation permet d’informer de la
                présence de cookie et éventuellement, de refuser de la manière
                décrite à l’adresse suivante : www.cnil.fr. Le refus
                d’installation d’un cookie peut entraîner l’impossibilité
                d’accéder à certains services. L’utilisateur peut toutefois
                configurer son ordinateur pour refuser l’installation des
                cookies.
              </p>

              <h2>
                Protection des biens et des personnes - gestion des données
                personnelles
              </h2>
              <p>
                Utilisateur : Internaute se connectant, utilisant le site
                susnommé : www.azerty-studio.fr
                <br /> En France, les données personnelles sont notamment
                protégées par la loi n° 78-87 du 6 janvier 1978, la loi n°
                2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la
                Directive Européenne du 24 octobre 1995.
              </p>
              <p>
                {" "}
                Sur le site www.azerty-studio.fr, le proprietaire du site ne
                collecte des informations personnelles relatives à l'utilisateur
                que pour le besoin de certains services proposés par le site
                www.azerty-studio.fr. L'utilisateur fournit ces informations en
                toute connaissance de cause, notamment lorsqu'il procède par
                lui-même à leur saisie. Il est alors précisé à l'utilisateur du
                site www.azerty-studio.fr l’obligation ou non de fournir ces
                informations.
                <br /> Conformément aux dispositions des articles 38 et suivants
                de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux
                fichiers et aux libertés, tout utilisateur dispose d’un droit
                d’accès, de rectification, de suppression et d’opposition aux
                données personnelles le concernant. Pour l’exercer, adressez
                votre demande à www.azerty-studio.fr par email : email du
                webmaster ou en effectuant sa demande écrite et signée,
                accompagnée d’une copie du titre d’identité avec signature du
                titulaire de la pièce, en précisant l’adresse à laquelle la
                réponse doit être envoyée.
              </p>
              <p>
                Aucune information personnelle de l'utilisateur du site
                www.azerty-studio.fr n'est publiée à l'insu de l'utilisateur,
                échangée, transférée, cédée ou vendue sur un support quelconque
                à des tiers. Seule l'hypothèse du rachat du site
                www.azerty-studio.fr à le proprietaire du site et de ses droits
                permettrait la transmission des dites informations à l'éventuel
                acquéreur qui serait à son tour tenu de la même obligation de
                conservation et de modification des données vis à vis de
                l'utilisateur du site www.azerty-studio.fr.{" "}
              </p>
              <p>
                Les bases de données sont protégées par les dispositions de la
                loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars
                1996 relative à la protection juridique des bases de données.
              </p>

              <h2>Responsabilité et force majeure</h2>
              <p>
                Les sources des informations diffusées sur le site sont réputées
                fiables. Toutefois, le site se réserve la faculté d'une
                non-garantie de la fiabilité des sources. Les informations
                données sur le site le sont à titre purement informatif. Ainsi,
                l'Utilisateur assume seul l'entière responsabilité de
                l'utilisation des informations et contenus du présent site.
              </p>
              <p>
                Tout usage du service par l'Utilisateur ayant directement ou
                indirectement pour conséquence des dommages doit faire l'objet
                d'une indemnisation au profit du site. Une garantie optimale de
                la sécurité et de la confidentialité des données transmises
                n'est pas assurée par le site. Toutefois, le site s'engage à
                mettre en œuvre tous les moyens nécessaires afin de garantir au
                mieux la sécurité et la confidentialité des données. La
                responsabilité du site ne peut être engagée en cas de force
                majeure ou du fait imprévisible et insurmontable d'un tiers.
              </p>

              <h2>Évolution du contrat</h2>
              <p>
                Le site se réserve à tout moment le droit de modifier les
                clauses stipulées dans le présent contrat.
              </p>

              <h2>Durée</h2>
              <p>
                La durée du présent contrat est indéterminée. Le contrat produit
                ses effets à l'égard de l'Utilisateur à compter de l'utilisation
                du service.
              </p>

              <h2>Droit applicable et juridiction compétente</h2>
              <p>
                La législation française s'applique au présent contrat. En cas
                d'absence de résolution amiable d'un litige né entre les
                parties, seuls les tribunaux de la ville de Nantes sont
                compétents.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MentionsLegales;

export const pageQuery = graphql`
  query {
    imageBanner: file(
      relativePath: { eq: "background/bannerMentionsLegales.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
